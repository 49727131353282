








import { Vue, Prop, Component } from 'nuxt-property-decorator'
import { csButtonTypes } from '~/helpers/csButtonUtils'

export type buttonType = typeof csButtonTypes[number];

@Component
export default class CsButton extends Vue {
  @Prop({ type: String, default: null }) readonly id!: string;
  @Prop({ type: String, default: 'primary' }) readonly buttonType!: buttonType;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
}
