






import { Vue, Component, Watch } from 'nuxt-property-decorator'
import { namespace } from 'vuex-class'

const message = namespace('message')

@Component({})
export default class ApplicationErrorAlert extends Vue {
  @message.Getter
  public getMessage!: string;

  @message.Getter
  public existsMessage!: boolean;

  @message.Action
  public clearMessage!: () => void;

  @Watch('existsMessage')
  show (): void {
    if (this.existsMessage) {
      this.$message({ message: this.getMessage, type: 'error' })
      setTimeout(this.clearMessage, 3000)
    }
  }
}
