/* eslint-disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /api/web/auth/sign_in(.:format) */
export function newApiWebUserSessionPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "auth" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/auth/sign_in(.:format) */
export function apiWebUserSessionPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "auth" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/auth/sign_out(.:format) */
export function destroyApiWebUserSessionPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "auth" + "/" + "sign_out" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/auth/password/new(.:format) */
export function newApiWebUserPasswordPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "auth" + "/" + "password" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/auth/password/edit(.:format) */
export function editApiWebUserPasswordPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "auth" + "/" + "password" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/auth/password(.:format) */
export function apiWebUserPasswordPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "auth" + "/" + "password" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/auth/validate_token(.:format) */
export function apiWebAuthValidateTokenPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "auth" + "/" + "validate_token" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/childminder_auto_updaters/exec_job(.:format) */
export function execJobApiWebChildminderAutoUpdatersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "childminder_auto_updaters" + "/" + "exec_job" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/childminder_auto_updaters(.:format) */
export function apiWebChildminderAutoUpdatersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "childminder_auto_updaters" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/support_chat(.:format) */
export function apiWebSupportChatIndexPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "support_chat" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/test(.:format) */
export function apiWebTestIndexPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "test" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/me(.:format) */
export function apiWebNurseryMePath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "me" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/check_settings(.:format) */
export function apiWebNurseryCheckSettingsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "check_settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/hq_organizations/destroy_targets(.:format) */
export function destroyTargetsApiWebNurseryHqOrganizationsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "hq_organizations" + "/" + "destroy_targets" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/hq_organizations(.:format) */
export function apiWebNurseryHqOrganizationsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "hq_organizations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/hq_organizations/:id(.:format) */
export function apiWebNurseryHqOrganizationPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "hq_organizations" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/check_assignable_childminders(.:format) */
export function apiWebNurseryCheckAssignableChildmindersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "check_assignable_childminders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/childminders/create_staff(.:format) */
export function createStaffApiWebNurseryChildmindersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "childminders" + "/" + "create_staff" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/childminders/create_childminders(.:format) */
export function createChildmindersApiWebNurseryChildmindersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "childminders" + "/" + "create_childminders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/childminders/update_childminders(.:format) */
export function updateChildmindersApiWebNurseryChildmindersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "childminders" + "/" + "update_childminders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/childminders/update_staffs(.:format) */
export function updateStaffsApiWebNurseryChildmindersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "childminders" + "/" + "update_staffs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/childminders/sort(.:format) */
export function sortApiWebNurseryChildmindersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "childminders" + "/" + "sort" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/childminders(.:format) */
export function apiWebNurseryChildmindersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "childminders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/childminders/:id(.:format) */
export function apiWebNurseryChildminderPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "childminders" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/garden_childminders(.:format) */
export function apiWebNurseryGardenChildmindersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "garden_childminders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/template_shifts(.:format) */
export function apiWebNurseryTemplateShiftsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "template_shifts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/template_shifts/:id(.:format) */
export function apiWebNurseryTemplateShiftPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "template_shifts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/shift_colors(.:format) */
export function apiWebNurseryShiftColorsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_colors" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/shift_absence_types(.:format) */
export function apiWebNurseryShiftAbsenceTypesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_absence_types" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/childminding_ratio_categories(.:format) */
export function apiWebNurseryChildmindingRatioCategoriesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "childminding_ratio_categories" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/childminder_combinations(.:format) */
export function apiWebNurseryChildminderCombinationsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "childminder_combinations" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/shift_groups/sort(.:format) */
export function sortApiWebNurseryShiftGroupsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_groups" + "/" + "sort" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/shift_groups/:shift_group_id/childminders(.:format) */
export function apiWebNurseryShiftGroupChildmindersPath(shift_group_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_groups" + "/" + shift_group_id + "/" + "childminders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_group_id","format"]);
}

/** /api/web/nursery/shift_groups(.:format) */
export function apiWebNurseryShiftGroupsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/shift_groups/:id(.:format) */
export function apiWebNurseryShiftGroupPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_groups" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/business_years(.:format) */
export function apiWebNurseryBusinessYearsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "business_years" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/shift_settings(.:format) */
export function apiWebNurseryShiftSettingsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/available_months(.:format) */
export function apiWebNurseryAvailableMonthsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "available_months" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/existing_months(.:format) */
export function apiWebNurseryExistingMonthsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "existing_months" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/master_update_wornings(.:format) */
export function apiWebNurseryMasterUpdateWorningsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "master_update_wornings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/owned_range_of_shifts(.:format) */
export function apiWebNurseryOwnedRangeOfShiftsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "owned_range_of_shifts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/is_any_custom_ranges(.:format) */
export function apiWebNurseryIsAnyCustomRangesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "is_any_custom_ranges" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/shift_managements/:id/reset_auto_input_flag(.:format) */
export function resetAutoInputFlagApiWebNurseryShiftManagementPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + id + "/" + "reset_auto_input_flag" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/mng_childminders/:mng_childminder_id/shifts(.:format) */
export function apiWebNurseryShiftManagementMngChildminderShiftsPath(shift_management_id: ScalarType, mng_childminder_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "mng_childminders" + "/" + mng_childminder_id + "/" + "shifts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","mng_childminder_id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/mng_childminders(.:format) */
export function apiWebNurseryShiftManagementMngChildmindersPath(shift_management_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "mng_childminders" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/mng_template_shifts(.:format) */
export function apiWebNurseryShiftManagementMngTemplateShiftsPath(shift_management_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "mng_template_shifts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/mng_shift_groups(.:format) */
export function apiWebNurseryShiftManagementMngShiftGroupsPath(shift_management_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "mng_shift_groups" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/shift_types(.:format) */
export function apiWebNurseryShiftManagementShiftTypesPath(shift_management_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "shift_types" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/events(.:format) */
export function apiWebNurseryShiftManagementEventsPath(shift_management_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "events" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/events/:id(.:format) */
export function apiWebNurseryShiftManagementEventPath(shift_management_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "events" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/daily_memos(.:format) */
export function apiWebNurseryShiftManagementDailyMemosPath(shift_management_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "daily_memos" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/daily_memos/:id(.:format) */
export function apiWebNurseryShiftManagementDailyMemoPath(shift_management_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "daily_memos" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/shift_prints(.:format) */
export function apiWebNurseryShiftManagementShiftPrintsPath(shift_management_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "shift_prints" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/update_mngs(.:format) */
export function apiWebNurseryShiftManagementUpdateMngsPath(shift_management_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "update_mngs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/restore_auto_shifts(.:format) */
export function apiWebNurseryShiftManagementRestoreAutoShiftsPath(shift_management_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "restore_auto_shifts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","format"]);
}

/** /api/web/nursery/shift_managements/:shift_management_id/yearly_statistics(.:format) */
export function apiWebNurseryShiftManagementYearlyStatisticsPath(shift_management_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + shift_management_id + "/" + "yearly_statistics" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["shift_management_id","format"]);
}

/** /api/web/nursery/shift_managements(.:format) */
export function apiWebNurseryShiftManagementsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/shift_managements/:id(.:format) */
export function apiWebNurseryShiftManagementPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_managements" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/run_auto_input_shifts/:id(.:format) */
export function apiWebNurseryRunAutoInputShiftPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "run_auto_input_shifts" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/default_shift_types(.:format) */
export function apiWebNurseryDefaultShiftTypesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "default_shift_types" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/childminding_ratios(.:format) */
export function apiWebNurseryChildmindingRatiosPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "childminding_ratios" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/basic_childminding_ratios(.:format) */
export function apiWebNurseryBasicChildmindingRatiosPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "basic_childminding_ratios" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/auto_shift_states(.:format) */
export function apiWebNurseryAutoShiftStatesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "auto_shift_states" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/auto_shift_targets(.:format) */
export function apiWebNurseryAutoShiftTargetsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "auto_shift_targets" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guests(.:format) */
export function apiWebNurseryGuestsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guests" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guests/:id(.:format) */
export function apiWebNurseryGuestPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guests" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/works/current(.:format) */
export function currentApiWebNurseryWorksPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "works" + "/" + "current" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/works/available_months(.:format) */
export function availableMonthsApiWebNurseryWorksPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "works" + "/" + "available_months" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/works/update_base(.:format) */
export function updateBaseApiWebNurseryWorksPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "works" + "/" + "update_base" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/works(.:format) */
export function apiWebNurseryWorksPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "works" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/works/:id(.:format) */
export function apiWebNurseryWorkPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "works" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/work_childminders/:id(.:format) */
export function apiWebNurseryWorkChildminderPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "work_childminders" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/work_logs/start_work(.:format) */
export function startWorkApiWebNurseryWorkLogsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "work_logs" + "/" + "start_work" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/work_logs/end_work(.:format) */
export function endWorkApiWebNurseryWorkLogsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "work_logs" + "/" + "end_work" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/work_logs/update_logs(.:format) */
export function updateLogsApiWebNurseryWorkLogsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "work_logs" + "/" + "update_logs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/work_logs(.:format) */
export function apiWebNurseryWorkLogsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "work_logs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/work_logs/:id(.:format) */
export function apiWebNurseryWorkLogPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "work_logs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/assignable_shifts(.:format) */
export function apiWebNurseryAssignableShiftsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "assignable_shifts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/fixed_count_shifts(.:format) */
export function apiWebNurseryFixedCountShiftsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "fixed_count_shifts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/prohibited_shift_sequences(.:format) */
export function apiWebNurseryProhibitedShiftSequencesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "prohibited_shift_sequences" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/shift_patterns(.:format) */
export function apiWebNurseryShiftPatternsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "shift_patterns" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/break_time_settings(.:format) */
export function apiWebNurseryBreakTimeSettingsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "break_time_settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/break_time_settings/:id(.:format) */
export function apiWebNurseryBreakTimeSettingPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "break_time_settings" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/request_auto_shifts(.:format) */
export function apiWebNurseryRequestAutoShiftsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "request_auto_shifts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/break_time_settings(.:format) */
export function apiWebNurseryGuestBreakTimeSettingsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "break_time_settings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/works/current(.:format) */
export function currentApiWebNurseryGuestWorksPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "works" + "/" + "current" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/works/available_months(.:format) */
export function availableMonthsApiWebNurseryGuestWorksPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "works" + "/" + "available_months" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/works/prev(.:format) */
export function prevApiWebNurseryGuestWorksPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "works" + "/" + "prev" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/works/update_base(.:format) */
export function updateBaseApiWebNurseryGuestWorksPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "works" + "/" + "update_base" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/works(.:format) */
export function apiWebNurseryGuestWorksPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "works" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/works/:id(.:format) */
export function apiWebNurseryGuestWorkPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "works" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/guest/work_childminders/:id(.:format) */
export function apiWebNurseryGuestWorkChildminderPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "work_childminders" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/nursery/guest/work_logs/start_work(.:format) */
export function startWorkApiWebNurseryGuestWorkLogsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "work_logs" + "/" + "start_work" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/work_logs/end_work(.:format) */
export function endWorkApiWebNurseryGuestWorkLogsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "work_logs" + "/" + "end_work" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/work_logs/update_logs(.:format) */
export function updateLogsApiWebNurseryGuestWorkLogsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "work_logs" + "/" + "update_logs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/work_logs(.:format) */
export function apiWebNurseryGuestWorkLogsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "work_logs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/nursery/guest/work_logs/:id(.:format) */
export function apiWebNurseryGuestWorkLogPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "nursery" + "/" + "guest" + "/" + "work_logs" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}
