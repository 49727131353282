import { NuxtAxiosInstance } from '@nuxtjs/axios'
import Rollbar from 'rollbar'
import { Commit, Dispatch } from 'vuex'
import { Route } from 'vue-router'
import { apiWebNurseryAutoShiftStatesPath } from '~/rails/routes'

type AutoShiftState = {
  processing: boolean;
  timeoutId: number | null;
};

type AutoShiftMutation = {
  setProcessing: (state: AutoShiftState, processing: boolean) => void;
  setTid: (state: AutoShiftState, timeoutId: number | null) => void;
};

type AutoShiftAction = {
  startPolling: (
    context: {
      state: AutoShiftState;
      commit: Commit;
      dispatch: Dispatch;
    },
    payload: {
      axios: NuxtAxiosInstance;
      rollbar?: Rollbar;
      reloadAfterPolling?: boolean;
      route?: Route;
      redirect?: (path: string) => void;
    }
  ) => void;
  runCheckProcessing: (
    context: {
      state: AutoShiftState;
      commit: Commit;
      dispatch: Dispatch;
    },
    payload: {
      axios: NuxtAxiosInstance;
      rollbar?: Rollbar;
      reloadAfterPolling?: boolean;
      route?: Route;
      redirect?: (path: string) => void;
    }
  ) => void;
};
type StateData = {
  processing: boolean;
  timeout?: boolean;
};

export const state: AutoShiftState = {
  processing: false,
  timeoutId: null
}

export const mutations: AutoShiftMutation = {
  setProcessing (state: AutoShiftState, processing: boolean): void {
    state.processing = processing
  },
  setTid (state: AutoShiftState, timeoutId: number | null): void {
    state.timeoutId = timeoutId
  }
}

export const actions: AutoShiftAction = {
  startPolling (
    { state, commit, dispatch }: { state: AutoShiftState; commit: Commit; dispatch: Dispatch },
    {
      axios,
      rollbar,
      timeout,
      reloadAfterPolling,
      route,
      redirect
    }: {
      axios: NuxtAxiosInstance;
      rollbar?: Rollbar;
      timeout?: number;
      reloadAfterPolling?: boolean;
      route?: Route;
      redirect?: (path: string) => void;
    }
  ): void {
    if (state.timeoutId) {
      return
    }

    const timeoutId = window.setTimeout(
      () => dispatch('runCheckProcessing', { axios, rollbar, reloadAfterPolling, route, redirect }),
      timeout || 3000
    )
    commit('setTid', timeoutId)
  },
  async runCheckProcessing (
    { state, commit, dispatch }: { state: AutoShiftState; commit: Commit; dispatch: Dispatch },
    {
      axios,
      rollbar,
      route,
      redirect
    }: { axios: NuxtAxiosInstance; rollbar?: Rollbar; route?: Route; redirect?: (path: string) => void }
  ): Promise<void> {
    try {
      commit('setTid', null)
      const data = await axios.$get<StateData>(apiWebNurseryAutoShiftStatesPath())
      if (data.processing) {
        if (route && route.path !== '/' && redirect) {
          redirect('/')
        }
        commit('setProcessing', true)
        // タイムアウトしていたら30分待つ
        const timeout = data.timeout ? 1800000 : 3000
        dispatch('startPolling', { axios, rollbar, timeout, route, redirect })
      } else if (state.processing) {
        commit('setProcessing', false)
        window.location.reload()
      }
    } catch (e) {
      console.error(e)
      rollbar?.error(e)
    }
  }
}
