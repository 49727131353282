

































import { Vue, Component, Prop, Emit } from 'nuxt-property-decorator'
import styled from 'vue-styled-components'
import logo from '~/assets/logo_header.png'
import HeaderPopover from '~/components/HeaderPopover.vue'
import { SelectedItem } from '~/components/NavbarSelectedItem'
import CsLink from '~/components/CsLink.vue'
import { unifaPolicyUrl, termsOfServiceUrl, portalDashboardUrl } from '~/helpers/constUrls'

const Wrapper = styled.div`
  height: 50px;
  background-color: #c39143;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0 20px;
  position: sticky;
  top: 0px;
  z-index: 1900;
`

const TextBlock = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 20px;
`

const NavItem = styled.div`
  cursor: pointer;
  padding: 3px 16px;
  font-weight: bold;
  border-radius: 16px;
  white-space: nowrap;
  img {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  &.right {
    margin-left: auto;
  }
  &.profile {
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 240px;
    > .name {
      margin-right: 5px;
      white-space: nowrap;
    }
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.selected {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &.no-hover {
    &:hover {
      background-color: inherit;
    }
  }
  transition: all 0.3s ease 0s;
`

const Logo = styled.div`
  cursor: pointer;
  background: url(${logo}) center / contain no-repeat;
  width: 108px;
  height: 40px;
`

@Component({
  components: {
    Wrapper,
    HeaderPopover,
    TextBlock,
    NavItem,
    Logo,
    CsLink
  }
})
export default class Navbar extends Vue {
  @Prop(String) readonly name?: string;
  @Prop({ type: String, default: 'top' }) readonly selectedItem!: SelectedItem;
  visible = false;
  policyUrl = unifaPolicyUrl;
  termsOfServiceUrl = termsOfServiceUrl;

  get isTop (): boolean {
    return this.selectedItem === 'top'
  }

  get isShift (): boolean {
    return this.selectedItem === 'shift'
  }

  get isWork (): boolean {
    return this.selectedItem === 'work'
  }

  get isSetting (): boolean {
    return this.selectedItem === 'setting'
  }

  linkTo (link: string): void {
    this.$router.push(link)
  }

  openPortalLink (): void {
    window.location.href = portalDashboardUrl()
  }

  toggele (): void {
    this.visible = !this.visible
  }

  alert (message: string): void {
    alert(message)
  }

  @Emit()
  logout (): void {} // eslint-disable-line @typescript-eslint/no-empty-function
}
