interface IMessageState {
  message: string;
}

interface IMessageMutation {
  setMessage: (state: IMessageState, message: string) => void;
  clearMessage: (state: IMessageState) => void;
}

interface IMessageGetter {
  getMessage: (state: IMessageState) => string;
  existsMessage: (state: IMessageState) => boolean;
}

interface IMessageAction {
  setMessage: (context: {commit: (arg: string, arg2: any) => void}, message: string) => void;
  clearMessage: (context: {commit: (arg: string) => void}) => void;
}

export const state: IMessageState = {
  message: ''
}

export const mutations: IMessageMutation = {
  setMessage (state: IMessageState, message: string) {
    state.message = message
  },
  clearMessage (state: IMessageState) {
    state.message = ''
  }
}

export const getters: IMessageGetter = {
  getMessage (state: IMessageState) {
    return state.message
  },
  existsMessage (state: IMessageState) {
    return state.message !== ''
  }
}

export const actions: IMessageAction = {
  setMessage ({ commit }, message: string) {
    commit('setMessage', message)
  },
  clearMessage ({ commit }) {
    commit('clearMessage')
  }
}
