
















import { Vue, Component } from 'nuxt-property-decorator'

import { apiWebNurseryMePath, destroyApiWebUserSessionPath } from '../rails/routes'
import Navbar from '~/components/Navbar.vue'
import { SelectedItem } from '~/components/NavbarSelectedItem'
import AutoShiftLoading from '~/components/shift_management/AutoShiftLoading.vue'
import Sidebar from '~/components/Sidebar.vue'
import { portalLogoutUrl } from '~/helpers/constUrls'
import ApplicationErrorAlert from '~/components/ApplicationErrorAlert.vue'

@Component({
  components: {
    Sidebar,
    Navbar,
    AutoShiftLoading,
    ApplicationErrorAlert
  }
})
export default class DefaultLayout extends Vue {
  isCollapseLS = localStorage.getItem('isSidebarCollapse') || '0'
  isCollapse = Boolean(parseInt(this.isCollapseLS, 10))
  async mounted (): Promise<void> {
    // 参照シフトが無い限り、毎回リクエストする
    if (!this.$store.state.me.name || this.$store.state.me.isReferenceRequired) {
      try {
        const me = await this.$axios.$get<{
          id: number;
          userTitle: string;
          nurseryId: number;
          name: string;
          isBeta: boolean;
          isReferenceRequired: boolean;
          userType: string;
          permissions: string[];
        }>(apiWebNurseryMePath())
        this.$store.commit('me/setId', me.id)
        this.$store.commit('me/setName', me.name)
        this.$store.commit('me/setIsReferenceRequired', me.isReferenceRequired)
        this.$store.commit('me/setUserTitle', me.userTitle)
        this.$store.commit('me/setPermissions', me.permissions)
        // NOTE: isBetaが必要になったら再度実装
        // this.$store.commit('me/setIsBeta', me.isBeta);
        const nurseryId = me.nurseryId
        const client = me.name
        localStorage.setItem('userType', me.userType)
        this.$gtm.pushEvent({ event: 'setClientName', clientName: client, nurseryId })
      } catch (e) {
        if (e.response?.status === 401) { return }

        console.error(e)
        this.rollbarError(e)
      }
    }
  }

  async logout (): Promise<void> {
    // TODO: 検証用のため確認ダイアログのデザイン未実装
    const res = await this.$axios.delete(destroyApiWebUserSessionPath())
    if (res.status === 200) {
      // 前回ログイン時isGuest取得できる場合は対象ログイン画面にredirect
      if (localStorage.getItem('isGuest') === 'true') {
        this.$router.push('/guest_login')
      } else {
        window.location.href = portalLogoutUrl()
      }
    } else {
      this.$message({
        message: 'ログアウトに失敗しました',
        type: 'error'
      })
    }
  }

  // NOTE: headerでフォーカスがあたっているメニューを返す
  get selectedNavbarItem (): SelectedItem {
    const shiftRegex = /^\/nursery\/shift_managements/
    const workRegex = /^\/nursery\/works/
    const settingRegex = /^\/nursery\/settings/

    if (shiftRegex.test(this.$route.path)) {
      return 'shift'
    } else if (workRegex.test(this.$route.path)) {
      return 'work'
    } else if (settingRegex.test(this.$route.path)) {
      return 'setting'
    } else {
      return 'top'
    }
  }

  // シフト表ページだけは下余白をなくしたいので、クラス名で制御する
  get isShiftManagementShowPage (): boolean {
    return /^\/nursery\/shift_managements\/[0-9]+$/.test(this.$route.path)
  }

  flipSidebar (): void {
    this.isCollapse = !this.isCollapse
    localStorage.setItem('isSidebarCollapse', Number(this.isCollapse).toString())
  }
}
