import { NuxtContext } from '~/types/nuxt'
import checkUpdate from '~/middleware/checkUpdate'
import { portalLogoutUrl } from '~/helpers/constUrls'

export default function (context: NuxtContext): void {
  const { $axios, app, redirect, store, rollbar, route, rollbarError } = context

  function redirectGuestLogin (): void {
    window.location.href = '/guest_login'
  }

  function redirectPortalLogin (): void {
    window.location.href = portalLogoutUrl()
  }
  $axios.onRequest((config) => {
    config.headers.client = app.$cookies.get('authClient')
    config.headers.uid = app.$cookies.get('authUId')
    // Devise側のToken
    config.headers['access-token'] = app.$cookies.get('authAccessToken')
    // Garden側のToken
    config.headers['Auth-Token'] = localStorage.authAccessToken || ''
  })

  $axios.onResponse((response) => {
    if (response.headers['auth-token']) {
      localStorage.authAccessToken = response.headers['auth-token']
    }
  })

  $axios.onError(async (error) => {
    const code = Number(error.response?.status)
    try {
      await checkUpdate()
    } catch (e) {
      // さらにエラーが発生すると困るので握りつぶす
      console.error(e)
      rollbarError(e)
    }

    if (code === 503) {
      // 最適実行中のシフトがある場合は画面ロック
      if (error.response?.data?.error === 'ProcessingAutoShiftExistsError') {
        if (!store.state.autoShift.processsing) {
          store.commit('autoShift/setProcessing', true)
          store.dispatch('autoShift/startPolling', { axios: $axios, rollbar, route, redirect })
        }
      }
    }
    if (code === 403 || code === 401) {
      // Application error 対応
      if (error.response?.data?.code || error.response?.data?.message) {
        store.commit('message/setMessage', error.response.data.message)
        if (localStorage.getItem('isGuest') === 'true') {
          setTimeout(redirectGuestLogin, 3000)
          return
        } else if (error.response?.data?.code === 'GARDEN_PERMISSION_FORBIDDEN') {
          throw error
        }
        setTimeout(redirectPortalLogin, 3000)
        return
      }
    }
    throw error
  })
}
