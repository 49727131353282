






import { Vue, Component } from 'nuxt-property-decorator'
import ApplicationErrorAlert from '~/components/ApplicationErrorAlert.vue'

@Component({ components: { ApplicationErrorAlert } })
export default class LoginLayout extends Vue {}
