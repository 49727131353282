export const questionnaireUrl =
  'https://docs.google.com/forms/d/e/1FAIpQLSe6E1bS2xFukHCMMONkbCVocO_6qe3A9mdJzKvoB3H4rXP0FA/viewform'

export const autoShiftQuestionnaireUrl =
  'https://docs.google.com/forms/d/1aeNCUkJDaLhdMRuvwpRUrIjwnkM8s4qW7dQOkSbq9UE/viewform'

export const unifaPolicyUrl = 'https://unifa-e.com/company/privacy.html'

export const termsOfServiceUrl = 'https://lookmee.jp/terms/index.html'

export function portalDomainUrl () {
  const domain = 'lookme-e.com'
  const userType = localStorage.getItem('userType') || ''
  let userTypePrefix = ''
  if (userType.includes('organization_admin') || userType.includes('teacher')) {
    userTypePrefix = 'portal.'
  } else if (userType.includes('operator')) {
    userTypePrefix = 'operator.'
  } else if (userType.includes('headquarters')) {
    userTypePrefix = 'headquarters.'
  }
  if (process.env.APP_ENV === 'staging') {
    return 'https://' + 'stg.' + userTypePrefix + domain
  } else if (process.env.APP_ENV === 'production') {
    return 'https://' + userTypePrefix + domain
  } else {
    return 'http://localhost:3001/login#' + userTypePrefix
  }
}

export function portalLogoutUrl () {
  return portalDomainUrl() + '/sso/end_session'
}

export function portalDashboardUrl () {
  return portalDomainUrl() + '/dashboard/organization'
}

function manageTeachersUrlString () {
  if (process.env.APP_ENV === 'production') {
    return 'https://portal.lookme-e.com/portal/teachers'
  } else {
    return 'https://stg.portal.lookme-e.com/portal/teachers'
  }
}

export const manageTeachersUrl = manageTeachersUrlString()

function customerFormsUrlString () {
  if (process.env.APP_ENV === 'production') {
    return 'https://portal.lookme-e.com/portal/customer_forms'
  } else {
    return 'https://stg.portal.lookme-e.com/portal/customer_forms'
  }
}

export const customerFormsUrl = customerFormsUrlString()

