








































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { NuxtError } from '@nuxt/types'
import styled from 'vue-styled-components'

import logo from '~/assets/logo_vertical.png'
import right from '~/assets/icons/caret_right.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
`

const Logo = styled.div`
  width: 133px;
  height: 200px;
  background: url(${logo}) center / contain no-repeat;
`

const ErrorTitle = styled.div`
  color: #222222;
  font-size: 18px;
  margin-top: 75px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: bold;
`

const ErrorDescription = styled.div`
  color: #222222;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
`

const TopLink = styled.div`
  margin-top: 30px;
  position: relative;
  a {
    color: #ac712a;
    &:active,
    &:focus,
    &:hover,
    &:visited {
      color: #ac712a;
    }
    :after {
      position: absolute;
      top: 8px;
      right: -15px;
      content: '';
      width: 12px;
      height: 12px;
      background: url(${right}) center / contain no-repeat;
    }
  }
`

@Component({
  components: {
    Wrapper,
    Logo,
    ErrorTitle,
    ErrorDescription,
    TopLink
  }
})
export default class ErrorPage extends Vue {
  // NOTE: https://github.com/nuxt/nuxt.js/blob/b83b5675043ebdfec3398bd83fd27dfe77cdd620/packages/vue-app/template/server.js#L71
  // より、エラーページのlayoutはstaticで呼ぶ必要がある。
  // 参考: https://qiita.com/Yama-Tomo/items/cfe0709dc6a81eb5742a

  @Prop(Object) readonly error!: NuxtError;
  get topPagePath (): string {
    if (localStorage.getItem('isGuest') === 'true') {
      return '/guest/'
    } else {
      return '/'
    }
  }
}
