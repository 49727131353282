import styled from 'vue-styled-components'

import close from '~/assets/icons/close_negative.svg'

export default styled.div`
  .el-dialog {
    border-radius: 6px;
    .el-dialog__header {
      padding: 35px 40px;
      border-bottom: 2px solid #bababa;
      .el-dialog__title {
        font-weight: bold;
        font-size: 28px;
        line-height: 1.5;
        color: #222222;
      }
      .el-dialog__headerbtn {
        top: 47px;
        right: 40px;
        .el-dialog__close {
          &:before {
            content: '';
            width: 20px;
            height: 20px;
            display: inline-block;
            background: url(${close}) center / contain no-repeat;
            vertical-align: top;
          }
          &:after {
            content: '閉じる';
            margin-left: 8px;
            font-size: 18px;
            color: #888888;
            text-decoration: underline;
          }
        }
        .el-dialog__close:hover {
          /* &:before {
            color: #C39143;
          } */
          &:after {
            color: #c39143;
          }
        }
      }
    }
    .el-dialog__body {
      padding: 35px 40px 40px 40px;
      color: #212121;
      font-size: 18px;
      line-height: 1.5;
      p:not(:last-child) {
        margin: 0 0 1em 0;
      }
      p:last-child {
        margin-bottom: 0;
      }
      p.bottom-wide {
        margin-bottom: 35px;
      }
    }
    .el-dialog__footer {
      padding: 10px 30px 30px 30px;
    }
  }
`
