import { NuxtContext } from '~/types/nuxt'

export default ({ app, redirect }: NuxtContext): void => {
  const authClient = app.$cookies.get('authClient')
  const authUId = app.$cookies.get('authUId')
  const authAccessToken = app.$cookies.get('authAccessToken')
  const isGuest = (localStorage.getItem('isGuest') === 'true')
  if (!authClient || !authUId || !authAccessToken || !isGuest) {
    redirect('/guest_login')
  }
}
